import { GetQueryResp } from '../types/Responses';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWRImmutable from 'swr/immutable';
import { useSnackbar } from '../../hooks/SnackBar';

const config: SWRConfiguration = {};

export const useQuerySwr = (uuid: string, revalidateOnMount = false) => {
  const { toggleIsLicValidity } = useSnackbar();
  config.revalidateOnMount = revalidateOnMount;
  const { data, error, isValidating } = useSWRImmutable<GetQueryResp>(
    `api/query/?uuid=${uuid}`,
    fetcherKeysToCamel,
    config
  );

  if (error) {
    toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
  } else {
    toggleIsLicValidity(false);
  }

  return {
    query: data,
    isLoading: !error && !data,
    isError: error,
    isValidating: isValidating,
  };
};
