import { OrderByCondition, WhereCondition } from '../types/Responses';

import { GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { generateUuid } from '../../modules/common';
import { MAX_COLUMN_TEXT_LENGTH } from '../const/maxTextLength';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

export const utcToJstFormat = (utcStr?: string) => {
  return utcStr ? dayjs.utc(utcStr).local().format('YYYY-MM-DD HH:mm:ss') : '';
};

export const renderCellDate = (
  params: GridRenderCellParams<{ [key: string]: any }>,
  property: string
) => {
  if (params.row[property] === undefined || params.row[property] === null) {
    return undefined;
  } else {
    const dateStr = utcToJstFormat(params.row[property]);
    const dateStrList = dateStr.split(' ');
    const yymmdd = dateStrList[0];
    const hhmmss = dateStrList[1];
    return (
      <div>
        <div>{yymmdd}</div>
        <div>{hhmmss}</div>
      </div>
    );
  }
};

export const simpleRenderCellDate = (params: string) => {
  if (!params) {
    return undefined;
  } else {
    const dateStr = utcToJstFormat(params);
    const dateStrList = dateStr.split(' ');
    const yymmdd = dateStrList[0];
    const hhmmss = dateStrList[1];
    return (
      <div>
        <div>{yymmdd}</div>
        <div>{hhmmss}</div>
      </div>
    );
  }
};

export const getSecond2String = (duration: number) => {
  const hour = Math.floor(duration / 3600000);
  const minute = Math.floor((duration - 3600000 * hour) / 60000);

  const hh = ('00' + hour).slice(-2);
  const mm = ('00' + minute).slice(-2);
  const ms = ('00000' + (duration % 60000)).slice(-5);

  const time = `${hh}:${mm}:${ms.slice(0, 2)}.${ms.slice(2, 5)}`;

  return time;
};

export const convertWhereConditionToString = (whereCondition: WhereCondition) => {
  if (whereCondition.type === 'time') {
    if ('values' in whereCondition && whereCondition.values !== null) {
      return [
        whereCondition.column,
        whereCondition.operator,
        whereCondition.values.join(' and '),
      ].join(' ');
    } else if ('unit' in whereCondition) {
      return [
        whereCondition.column,
        whereCondition.operator,
        whereCondition.value,
        whereCondition.unit,
      ].join(' ');
    }
  } else if (whereCondition.type === 'text') {
    return [whereCondition.column, whereCondition.operator, whereCondition.value].join(' ');
  } else if (whereCondition.type === 'num') {
    return [whereCondition.column, whereCondition.operator, whereCondition.value].join(' ');
  } else if (whereCondition.type === 'bool') {
    return [whereCondition.column, 'is', whereCondition.value].join(' ');
  }
};
export const convertOrderByConditionToString = (orderByCondition: OrderByCondition) => {
  if (orderByCondition.order === 'asc') {
    return [`${orderByCondition.column}：`, '昇順↑'].join(' ');
  } else {
    return [`${orderByCondition.column}：`, '降順↓'].join(' ');
  }
};

export const serializeWhere = (where: WhereCondition[]) => {
  if (where === undefined) {
    return <></>;
  }
  const whereStrList = where.map((element: WhereCondition) => {
    return convertWhereConditionToString(element);
  });
  return (
    <div>
      {whereStrList.map((whereCondition) => (
        <div key={generateUuid()}>{whereCondition}</div>
      ))}
    </div>
  );
};

export const serializeOrderBy = (orderByList: OrderByCondition[]) => {
  if (orderByList === undefined) {
    return <></>;
  }
  const orderByStrList = orderByList.map((element: OrderByCondition) => {
    return convertOrderByConditionToString(element);
  });
  return (
    <div>
      {orderByStrList.map((orderByCondition) => (
        <div key={generateUuid()}>{orderByCondition}</div>
      ))}
    </div>
  );
};

export const shortableWhereConditionColumn = (whereCondition: WhereCondition) => {
  return {
    ...whereCondition,
    column:
      whereCondition.column.length > MAX_COLUMN_TEXT_LENGTH
        ? `${whereCondition.column.slice(0, MAX_COLUMN_TEXT_LENGTH)}...`
        : whereCondition.column,
  };
};

export const shortableOrderByConditionColumn = (orderByCondition: OrderByCondition) => {
  return {
    ...orderByCondition,
    column:
      orderByCondition.column.length > MAX_COLUMN_TEXT_LENGTH
        ? `${orderByCondition.column.slice(0, MAX_COLUMN_TEXT_LENGTH)}...`
        : orderByCondition.column,
  };
};
