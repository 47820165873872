import CreateSimpleQueryForm from './CreateSimpleQueryForm';
import { useQuerySwr } from '../../common/swr/useQuerySwr';
import { useSnackbar } from '../../hooks/SnackBar';
import { useEffect } from 'react';
import Progress from './Progress';

type Props = {
  uuid: string;
};

export default function EditSimpleQueryForm({ uuid }: Props) {
  const { showSnackbar } = useSnackbar();

  // APIs
  const { query, isLoading, isError, isValidating } = useQuerySwr(uuid, true);

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`ダウンロード条件取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  return (
    <>
      {isLoading || isValidating ? (
        <Progress open={true} />
      ) : (
        <CreateSimpleQueryForm query={query}></CreateSimpleQueryForm>
      )}
    </>
  );
}
