export const UserRole = {
  Superadmin: 'superadmin',
  Admin: 'admin',
  NotAdmin: 'notAdmin',
} as const;
// eslint-disable-next-line prettier/prettier
export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export const AllRoleType = Object.values(UserRole);

export type UserType = {
  name: string;
  role: UserRole;
};

// フロントエンドで取り扱う際のユーザー権限
export const FrontendUserRole = {
  // システム管理者
  SuperAdmin: 'superAdmin',
  // 管理者ユーザー
  Admin: 'admin',
  // 編集権限ユーザー
  Edit: 'edit',
  // 閲覧権限ユーザー
  Read: 'read',
  // グループ未所属ユーザー
  NotAffiliation: 'notAffiliation',
  // ロール取得中
  Pending: 'pending',
} as const;
// eslint-disable-next-line prettier/prettier
export type FrontendUserRole = (typeof FrontendUserRole)[keyof typeof FrontendUserRole];

export type User = {
  firstId: string;
  userName: string;
  adminRole: UserRole;
  email: string;
  updatedAt: string;
  group: MinimalGroup[];
  ceratedAt?: string;
  mfaStatus?: string;
};

export type Group = {
  firstId: string;
  groupName: string;
  tables: GroupsTable[];
  schemas: SchemasTable[];
  users: GroupsUser[];
  createdAt: string;
  updatedAt: string;
};

export type GroupsTable = {
  connectionId: string;
  dbN: string;
  schemaN: string;
  tableN: string;
};

export type SchemasTable = {
  connectionId: string;
  dbN: string;
  schemaN: string;
};

export type GroupsUser = {
  firstId: string;
  role: string;
};

export type Connections = {
  connectionId: string;
  name: string;
  type: string;
  host: string;
  port: number;
  user: string;
  db: string;
};

export type MinimalGroup = {
  groupId: string;
  role: string;
};

export type Tables = Table[];
export type Table = {
  schemaN: string;
  tables: string[];
};

export type QueryDelete = {
  deleteNum: number;
};

export type DatasourceType = 'Redshift' | 'Snowflake';

export type License = {
  licenseKey: string | null;
  expireDate: string;
  userNum: number;
  datasourceNum: number;
  datasourceType: DatasourceType[];
  isValid: boolean;
  checkedAt: string;
  createdAt: string;
  updatedAt: string;
  isApproaching?: boolean;
  isExpired?: boolean;
};

export type SqlInfo = {
  select?: {
    key: string;
    value: string;
  };
  from?: {
    key: string;
    value: string;
  };
  where?: {
    key: string;
    value: string;
  };
  orderBy?: {
    key: string;
    value: string;
  };
};
