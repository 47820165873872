import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { moveArrayItem } from '../modules/common';

enum ITEM_TYPE {
  CARD = 'Card',
}

export const DndWrapper: React.FC<{
  index: number;
  children: React.ReactNode;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
  onDropEnd?: () => void;
}> = ({ children, onMoveItem, index, onDropEnd }) => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * define drop function from react-dnd
   * when user drag an element and hover to another element,
   * hover function will call and calculate new index for element then wap it
   */
  const [, drop] = useDrop<{ index: number }>({
    accept: [ITEM_TYPE.CARD],
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset() ?? { x: 0, y: 0 };
      const hoverClientY = clientOffset.x - hoverBoundingRect.left;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },

    drop: () => {
      onDropEnd?.();
    },
  });

  /**
   * define drag function from react-dnd
   * @param: isDragging => tracking when elmenet is dragging
   */
  const [{ isDragging }, drag] = useDrag<{ index: number }, null, { isDragging: boolean }>({
    item: { index },
    type: ITEM_TYPE.CARD,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // register div element with ref to Drag and Drop monitor
  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  return (
    <div style={{ opacity }} ref={ref}>
      {children}
    </div>
  );
};

export const DndContainer: React.FC<{
  data: string[];
  renderItem: (item: string) => React.ReactNode;
  onDropEnd?: (data: string[]) => void;
}> = ({ data: dataP, renderItem, onDropEnd }) => {
  const [data, setData] = useState<string[]>([]);

  const onMoveItem = useCallback((drag: number, hover: number) => {
    setData((s) => {
      return moveArrayItem(s, drag, hover);
    });
  }, []);

  useEffect(() => {
    setData(dataP);
  }, [dataP]);

  return (
    <DndProvider backend={HTML5Backend}>
      {data.length
        ? data.map((row, i) => {
            return (
              <DndWrapper
                onDropEnd={() => onDropEnd?.(data)}
                index={i}
                onMoveItem={onMoveItem}
                key={row}
              >
                {renderItem(row)}
              </DndWrapper>
            );
          })
        : null}
    </DndProvider>
  );
};
