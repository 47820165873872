import { SchemaTableSetting, SimpleQueryReq } from '../../common/types/Responses';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import utc from 'dayjs/plugin/utc';
import { BaseDataGrid } from '../BaseDataGrid';

dayjs.extend(utc);

type Props = {
  formik: FormikProps<SimpleQueryReq>;
  columnsDef: GridColDef[];
  columnsRow: SchemaTableSetting[];
  isLoading?: boolean;
};

const SchemaConditionParts: React.FC<Props> = ({
  formik,
  columnsDef: columnsDef,
  columnsRow: columnsRow,
  isLoading,
}: Props) => {
  // ダイアログの表示/非表示
  const [dialogOpen, setDialogOpen] = useState(false);

  // ダイアログを開く（変更）
  const onClickSettingWhereCondition = (condition: SchemaTableSetting) => {
    setSelectedColumn({ schemaN: condition.schemaN, tableN: condition.tableN });
    setSelectionModel([`${condition.schemaN + condition.tableN}`]);
    setDialogOpen(true);
  };

  // ダイアログを開く
  const onClickDialogOpen = () => {
    setDialogOpen(true);
  };

  // 設定するボタン
  const handleDialogOk = () => {
    formik.setFieldValue('schemaN', selectedColumn?.schemaN);
    formik.setFieldValue('tableN', selectedColumn?.tableN);

    // 抽出する項目の設定 リセット
    formik.setFieldValue('simple.select', []);
    // 抽出条件の設定 リセット
    formik.setFieldValue('simple.where', []);
    // 並び順の設定 リセット
    formik.setFieldValue('simple.orderby', []);

    setDialogOpen(false);
  };

  // キャンセルボタン
  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  // 選択している行
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [selectedColumn, setSelectedColumn] = React.useState<SchemaTableSetting>();

  const schemaRadioColumn: GridColDef = {
    field: 'radiobutton',
    headerName: '',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <Radio
        checked={`${selectedColumn?.schemaN}${selectedColumn?.tableN}` === params.id}
        value={params.id}
      />
    ),
  };

  return (
    <>
      <Grid container marginTop={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography>
            スキーマとテーブル名 <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        {formik.values.schemaN ? (
          <Grid item>
            <Paper variant="outlined">
              <Grid container padding={2} alignItems="center">
                <Grid item paddingRight={5}>
                  <Typography variant="caption">スキーマ</Typography>
                  <Typography>{formik.values.schemaN}</Typography>
                </Grid>

                <Grid item paddingRight={5}>
                  <Typography variant="caption">テーブル</Typography>
                  <Typography>{formik.values.tableN}</Typography>
                </Grid>

                <Grid item paddingRight={2}>
                  <Button
                    variant="text"
                    onClick={() =>
                      onClickSettingWhereCondition({
                        schemaN: formik.values.schemaN,
                        tableN: formik.values.tableN,
                      })
                    }
                  >
                    変更
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {formik.values.datasource ? (
              <Button variant="text" onClick={onClickDialogOpen}>
                テーブルを選択
              </Button>
            ) : (
              <Typography component="span" variant="caption">
                データソース選択してください。
              </Typography>
            )}
          </Grid>
        )}
      </Grid>

      <Dialog
        fullWidth
        maxWidth="md"
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6" marginBottom={2}>
            テーブルを選択
          </Typography>
          <Grid container spacing={3} mb={2} justifyContent="center">
            <Grid item xs={12}>
              <Paper>
                <div style={{ display: 'flex', height: '100%' }}>
                  <BaseDataGrid
                    loading={isLoading}
                    rows={columnsRow}
                    columns={[schemaRadioColumn].concat(columnsDef)}
                    selectionModel={selectionModel}
                    disableSelectionOnClick={false}
                    getRowIdFunc={(row: SchemaTableSetting) => `${row.schemaN + row.tableN}`}
                    onSelectionModelChangeFunc={(selectionModel: GridRowSelectionModel) => {
                      if (selectionModel.length !== 0) {
                        const selectedColumn = columnsRow.find(
                          (row) => `${row.schemaN + row.tableN}` === selectionModel[0]
                        );
                        setSelectedColumn(selectedColumn);
                      } else {
                        setSelectedColumn(undefined);
                      }
                      setSelectionModel(selectionModel);
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'schemaN', sort: 'asc' }],
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    sx={{
                      '.MuiDataGrid-row.Mui-selected': {
                        backgroundColor: 'rgba(25, 118, 240, 0.3)',
                        ':hover': { backgroundColor: 'rgba(25, 118, 240, 0.3)' },
                      },
                      '.MuiDataGrid-row': {
                        ':hover': { backgroundColor: 'rgba(25, 118, 240, 0.1)', cursor: 'pointer' },
                      },
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ margin: 4 }}>
          <Button onClick={handleDialogCancel} color="inherit" sx={{ marginRight: 2 }}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogOk}
            color="primary"
            autoFocus
            disabled={selectionModel.length === 0}
          >
            設定する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SchemaConditionParts;
