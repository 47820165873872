import { Tables } from '../types';
import type { SWRConfiguration } from 'swr';
import { arrayFetcherKeysToCamel } from './fetcher';
import useSWRImmutable from 'swr/immutable';

const config: SWRConfiguration = {};

export const useTablesSwr = (
  connectionIds: string[],
  shouldFetch: boolean,
  onlySchema?: boolean
) => {
  const tablesUrls: Array<string> = connectionIds?.map(
    (connectionId) => `api/tables/?connection_id=${connectionId}&only_schema=${onlySchema ?? false}`
  );
  if (tablesUrls.length === 0) {
    tablesUrls.push('');
  }

  const { data, error: tablesError } = useSWRImmutable<Tables[]>(
    tablesUrls.join(','),
    () => arrayFetcherKeysToCamel(...tablesUrls),
    config
  );

  const tables = data ? data : [];

  return {
    tables: shouldFetch ? tables : [],
    isLoading: !data,
    isError: tablesError,
  };
};
