import { useLocation, useParams } from 'react-router-dom';

import { BaseContent } from '../components/BaseContent';
import CreateSimpleQueryForm from '../components/layoutparts/CreateSimpleQueryForm';
import EditSimpleQueryForm from '../components/layoutparts/EditSimpleQueryForm';
import { GetQueryResp } from '../common/types/Responses';

type Props = {
  hiddenBaseContent?: boolean;
};

export default function SimpleQueryPage({ hiddenBaseContent }: Props) {
  // 更新処理の遷移
  const { uuid } = useParams();
  // クエリ一覧からの遷移(コピーして作成)
  const location = useLocation();
  const query: GetQueryResp = {
    uuid: '',
    datasource: location.state?.query?.datasource,
    queryName: location.state?.query?.queryName,
    description: location.state?.query?.description,
    query: location.state?.query?.query,
    schemaN: location.state?.query?.schemaN,
    tableN: location.state?.query?.tableN,
    simple: location.state?.query?.simple,
    csvBomOption: location.state?.query?.csvBomOption ?? false,
    createdAt: location.state?.query?.createdAt,
    updatedAt: location.state?.query?.updatedAt,
    lastDownloadAt: location.state?.query?.lastDownloadAt,
  };

  // データソース一覧からの遷移(データソースだけ選択)
  const datasource: GetQueryResp = {
    uuid: '',
    datasource: location.state?.connection?.connectionId,
    queryName: '',
    description: '',
    query: undefined,
    schemaN: '',
    tableN: '',
    simple: undefined,
    csvBomOption: true,
    createdAt: '',
    updatedAt: '',
    lastDownloadAt: '',
  };

  return (
    <>
      {hiddenBaseContent ? (
        <>
          {(() => {
            if (uuid) {
              return <EditSimpleQueryForm uuid={uuid} />;
            } else {
              if (location.state?.query) {
                return <CreateSimpleQueryForm query={query} />;
              } else if (location.state?.connection) {
                return <CreateSimpleQueryForm query={datasource} />;
              } else {
                return <CreateSimpleQueryForm />;
              }
            }
          })()}
        </>
      ) : (
        <BaseContent title="シンプルな条件設定">
          {(() => {
            if (uuid) {
              return <EditSimpleQueryForm uuid={uuid} />;
            } else {
              if (location.state?.query) {
                return <CreateSimpleQueryForm query={query} />;
              } else if (location.state?.connection) {
                return <CreateSimpleQueryForm query={datasource} />;
              } else {
                return <CreateSimpleQueryForm />;
              }
            }
          })()}
        </BaseContent>
      )}
    </>
  );
}
